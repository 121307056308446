@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap");

$robotoFamily: "Roboto", sans-serif;
$sedgwick: "Sedgwick Ave", cursive;
$bgColor1: #f8f8f8;

$textColor1: #575656;
$textColor2: #626060;
$textColor3: #a6a6a6;
$textColor4: #7a7b7b;
$white: #ffffff;
$primary: #008df4;
$danger: #f96161;
$darkBlack: #050505;

$fontSize: 16px;
$left: left;
$right: right;
$center: center;
$relative: relative;
$absolute: absolute;

$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 1024px,
  lg: 1200px,
);

@function get-clamp($min: 16px, $val: 3vw, $max) {
  @return ($min, $val, $max);
}

@mixin font($fontFamily: null, $fontSize: null, $fontWeight: null, $fontStyle: normal, $lineHeight: null, $color: null, $textAlign: null, $textDecoration: null, $letterSpacing: null) {
  color: $color;
  font-family: $fontFamily;
  font-weight: $fontWeight;
  font-style: $fontStyle;
  text-align: $textAlign;
  text-decoration: $textDecoration;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin typography($color: null, $family: null, $size: null, $weight: null, $style: normal, $height: null, $align: null, $transform: null, $opacity: null) {
  color: $color;
  font-family: $family;
  @if $size != null {
    font-size: $size;
  }
  @if $weight != null {
    font-weight: calcWeight($weight);
  }
  @if $height != null {
    line-height: calcRem($height);
  }
  font-style: $style;
  text-align: $align;
  text-transform: $transform;
  opacity: $opacity;
}

@mixin box(
  $background: null,
  $border: null,
  $color: null,
  $radius: null,
  $margin: null,
  $padding: null,
  $width: null,
  $height: null,
  $overflow: null,
  $alignContent: null,
  $display: null,
  $gridTemplate: null,
  $flexDirection: null,
  $flexWrap: null,
  $flexGrow: null,
  $alignItems: null,
  $justifyContent: null,
  $gap: null,
  $rowGap: null,
  $columnGap: null,
  $justifySelf: null,
  $alignSelf: null,
  $textAlign: null,
  $position: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $zIndex: null,
  $objectFit: null,
  $minWidth: null,
  $maxWidth: null,
  $minHeight: null,
  $maxHeight: null,
  $cursor: null,
  $content: null,
  $opacity: null,
  $outline: null,
  $boxShadow: null,
  $transform: null
) {
  background: $background;
  border: $border;
  border-radius: $radius;
  color: $color;
  margin: $margin;
  padding: $padding;
  overflow: $overflow;
  align-content: $alignContent;
  display: $display;
  grid-template-columns: $gridTemplate;
  flex-direction: $flexDirection;
  flex-wrap: $flexWrap;
  flex-grow: $flexGrow;
  align-items: $alignItems;
  justify-content: $justifyContent;
  justify-self: $justifySelf;
  align-self: $alignSelf;
  gap: $gap;
  row-gap: $rowGap;
  column-gap: $columnGap;
  text-align: $textAlign;
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  width: $width;
  height: $height;
  z-index: $zIndex;
  object-fit: $objectFit;
  min-width: $minWidth;
  max-width: $maxWidth;
  min-height: $minHeight;
  max-height: $maxHeight;
  cursor: $cursor;
  content: $content;
  opacity: $opacity;
  outline: $outline;
  box-shadow: $boxShadow;
  transform: $transform;
}

@mixin margin($all: null, $top: null, $right: null, $bottom: null, $left: null, $rectangle: null) {
  margin: $all;
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
  margin: $rectangle;
}

@mixin padding($all: null, $top: null, $right: null, $bottom: null, $left: null) {
  padding: $all;
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin line-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin media-breakpoint-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-breakpoint-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin media-breakpoint-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint + 1) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
html {
  overflow-x: hidden;
}
body {
  font-family: $robotoFamily;
}
@mixin transition($property: all, $duration: 0.3s, $ease: ease-in-out) {
  -webkit-transition: $property $duration $ease;
  -moz-transition: $property $duration $ease;
  -o-transition: $property $duration $ease;
  transition: $property $duration $ease;
}

@mixin transition2($duration: 0.25s) {
  transition: $duration cubic-bezier(0.33, 0.04, 0.63, 0.93), -webkit-transform $duration cubic-bezier(0.33, 0.04, 0.63, 0.93), -o-transform $duration cubic-bezier(0.33, 0.04, 0.63, 0.93);
}

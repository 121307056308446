@import "./variables.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

body {
  height: 100%;
}

#root {
  position: relative;
  min-height: 100%;
}

.header {
  @include box($background: transparent, $height: 100px, $display: flex, $alignItems: center, $justifyContent: space-between, $position: sticky, $top: 0px, $zIndex: 2);
  @include padding($left: 5%, $right: 5%);
  @include transition;
  @include media-breakpoint-below(sm) {
    height: 55px;
  }
}

.headerNav {
  @include margin($all: 0 -3%);
  @include box($display: flex, $flexDirection: row, $alignItems: center);
  @include transition;

  & > a {
    @include box($margin: 0 clamp(10px, 1.5vw, 30px), $padding: clamp(5px, 1.5vw, 10px));
    @include font($fontSize: clamp(12px, 1.3vw, 22px), $lineHeight: clamp(16px, 1.5vw, 26px), $color: $white, $fontWeight: 500, $textDecoration: none);
    @include transition2;
  }
}

.headerBg {
  @include box($background: $white, $opacity: 1, $height: 55px, $alignItems: center);
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);

  .headerNav {
    & > a {
      color: $textColor1;
    }
  }

  .appLogo {
    img {
      width: clamp(60px, 15vw, 150px);
    }
  }
}

.appLogo {
  @include transition;

  img {
    @include transition;
    width: clamp(60px, 15vw, 150px);
  }
}

.header-wrapper {
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  .headerNav {
    & > * {
      color: #555555;
    }
  }
}

.topSection {
  @include box($background: url("../images/top-cover-bg.png"), $height: 100%, $position: relative);
  @include margin($top: -100px);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  .topSectionBg {
    @include box($width: 100%, $position: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);

    img {
      @include box($width: 100%, $height: 100%, $objectFit: fill);
    }
  }

  article {
    @include box($position: relative, $width: 50%);
    @include padding($left: 5%, $top: 12%, $bottom: 5%);
  }

  h1 {
    @include font($fontSize: clamp(18px, 3.5vw, 62px), $lineHeight: clamp(20px, 4vw, 81px), $fontWeight: 700, $color: $white);
  }

  p {
    @include font($fontSize: clamp(14px, 1.4vw, 24px), $lineHeight: clamp(18px, 2.5vw, 40px), $color: $white);
    @include margin($top: clamp(15px, 8%, 50px));
    width: clamp(210px, 80%, 660px);
  }

  @include media-breakpoint-below(sm) {
    @include margin($top: -55px);
    article {
      @include padding($top: 16%);
    }
    p {
      width: clamp(120px, 80%, 660px);
      // @include line-clamp(2);
    }
  }

  @include media-breakpoint-below(xs) {
    article {
      width: 65%;
      @include padding($top: 17%);
    }
    h1,
    p {
      // @include line-clamp(2);
      width: clamp(120px, 80%, 660px);
    }
  }
}

.brandsContainer {
  @include box($overflow: hidden, $margin: 0 -1%, $padding: min(6vw, 6%) 0, $width: 100%);

  .slick-track {
    @include box($display: flex, $flexDirection: row, $alignItems: center);
  }

  .brandsLogo {
    text-align: center;
    @include padding($all: 0 3vw);
    @include transition;

    img {
      height: 42px;
    }
  }

  @include media-breakpoint-below(xs) {
    .brandsLogo {
      @include padding($all: 0 15%);
    }
  }
}

.main-wrapper {
  padding: 24px 64px;
  @include media-breakpoint-below(sm) {
    padding: 16px;
  }
}

.email_verify {
  @include margin($top: 50px);
  text-align: center;
  width: 100%;
  height: 90vh;
  @include typography($size: 28px, $family: $robotoFamily, $color: $darkBlack);

  &_message {
    @include margin($rectangle: 10px 0px 20px 0px);
    line-height: normal;
  }
}

.contents {
  @include box($display: grid, $gridTemplate: 1.2fr 1fr, $alignItems: center);
  @include padding($top: 5%, $bottom: 5%, $left: 40px);
  @include transition;
  @include media-breakpoint-below(xs) {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  article {
    @include margin($right: 40px);
    @include transition;

    h1 {
      @include font($fontSize: clamp(20px, 2vw, 32px), $fontWeight: 500, $lineHeight: clamp(24px, 5vw, 36px), $color: $textColor2);
    }

    p,
    li {
      @include font($fontSize: clamp(14px, 1.2vw, 21px), $lineHeight: clamp(18px, 2.4vw, 43px), $color: $textColor1);
    }

    p {
      @include margin($top: max(20px, 5%), $bottom: max(10px, 2%));
    }

    ul {
      list-style: none;

      li {
        @include box($display: flex, $flexDirection: row, $justifyContent: flex-start, $alignItems: flex-start);
        @include margin($top: max(20px, 5%));
      }
    }
  }

  @include media-breakpoint-below(xs) {
    @include padding($left: 5%);
    article {
      @include margin($top: 5%, $right: 5%);
    }
  }
}

.bullets {
  $width: 25px;
  @include box($background: rgba($primary, 0.16), $radius: 50%, $width: $width, $height: $width, $minWidth: $width, $minHeight: $width, $position: relative);
  @include margin($top: 0.6%, $right: 5%);
  @include transition;

  &::after {
    content: "";
    @include box($background: $primary, $radius: 50%, $position: absolute, $width: 9px, $height: 9px, $top: 50%, $left: 50%);
    transform: translate(-50%, -50%);
  }

  @include media-breakpoint-below(xs) {
    $width: 18px;
    @include box($width: $width, $height: $width, $minWidth: $width, $minHeight: $width);
    @include margin($top: 0%, $right: 4%);
  }
}

.contentsBg {
  background: $bgColor1;
}

.bookDemoButton {
  @include box($background: transparent, $border: 2px solid #f1f1f1, $radius: 40px, $display: inline-flex, $flexDirection: row, $alignItems: center, $cursor: pointer);
  @include font($fontSize: clamp(12px, 1.4vw, 22px), $lineHeight: clamp(16px, 2.5vw, 26px), $fontWeight: 500, $color: $white);
  @include padding($all: clamp(5px, 0.8vw, 20px) clamp(10px, 2vw, 30px));
  @include margin($top: 10%);

  img {
    @include margin($left: 15px);
  }

  @include media-breakpoint-below(xs) {
    @include margin($top: 8%);
    img {
      @include margin($left: 8px);
    }
  }
}

.bookDemoButtonBlue {
  @include box($background: $primary, $color: $white);
  @include margin($top: 2%);
  transition: all 0.4s ease-in-out;
  &:hover {
    background: #69bd28;
  }
}

#footer {
  @include box($background: $bgColor1, $padding: 20px 40px, $textAlign: center, $position: absolute);
  border-top: 1px solid #ccc;
  bottom: 0px;
  right: 0px;
  left: 0px;
  @include media-breakpoint-below(xs) {
    text-align: right;
  }

  .footerGrid {
    @include box($display: flex, $flexDirection: row, $alignContent: center, $justifyContent: space-between, $textAlign: right);
    @include margin($top: 15px);

    @include media-breakpoint-below(xs) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0;
    }

    .footerContact {
      @include font($fontSize: clamp(14px, 1.1vw, 20px), $lineHeight: clamp(18px, 2vw, 24px), $color: $textColor1, $textAlign: left);

      a {
        color: $textColor1;
      }

      span {
        font-weight: 500;
      }
    }

    .copyright {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include font($fontSize: clamp(12px, 1vw, 18px), $lineHeight: clamp(16px, 2vw, 22px), $color: $textColor4);
      @include media-breakpoint-below(xs) {
        margin-top: 16px;
      }
      .privacy {
        cursor: pointer;
      }
    }
  }

  @include media-breakpoint-below(xs) {
    @include padding($all: 5%);
  }
}

.footerLogo {
  @include box($position: absolute, $left: 40px, $top: 25%, $width: clamp(60px, 15vw, 150px));
  @include transition;
  @include media-breakpoint-below(xs) {
    left: 5%;
    top: 15%;
  }
}

.privacyPolicy {
  margin-left: 80px;
  margin-top: 30px;
}

.image404Box {
  div {
    width: 40% !important;
    padding: 5%;
  }

  svg {
    width: 50%;
  }
}

.pageNotFoundText {
  @include font($fontSize: 18px, $color: $textColor1, $textAlign: center);
}

.bookingModal {
  .ant-modal-close {
    display: none;
  }

  .ant-input:not(textarea) {
    @include box($height: 28px);
  }

  .ant-input {
    border-radius: 4px;
  }

  .ant-form-item textarea.ant-input {
    height: 100px;
  }

  .ant-form-item-required {
    &::before {
      content: "" !important;
      margin-right: 0px !important;
    }

    &::after {
      content: "*" !important;
      color: $danger;
    }
  }
}

.formHeader {
  @include box($background: rgba(85, 110, 230, 0.25), $display: flex, $flexDirection: row, $justifyContent: space-between, $alignItems: center);

  h3 {
    @include font($fontSize: clamp(20px, 4vw, 24px), $lineHeight: clamp(24px, 6vw, 28px), $color: #151929);
    @include margin($left: 6%);
  }
}

.formBody {
  @include padding($all: 4% 6%);

  .ant-row {
    @include box($display: flex, $flexDirection: column, $position: relative);

    label {
      display: block;
      @include font($fontSize: 13px, $lineHeight: 19px, $color: $textColor1, $fontWeight: 500);
      @include margin($bottom: 8px);
    }
  }

  .ant-form-item-explain {
    min-height: 1px;
  }

  .ant-form-item-explain-error {
    @include margin($top: 8px);
  }

  .ant-form-item {
    @include box($display: flex, $flexDirection: column);
    @include margin($bottom: 20px);

    .ant-form-item-label {
      text-align: left;
    }

    @include media-breakpoint-below(xs) {
      @include margin($bottom: 10px);
    }
  }

  .ant-btn {
    border-radius: 4px;
  }
}

.formFooter {
  @include box($display: flex, $flexDirection: row, $justifyContent: space-between);
  @include margin($top: 10px);
}

.thankYouMessage {
  @include box($display: flex, $flexDirection: column, $justifyContent: space-around, $alignItems: center, $textAlign: center);

  h2 {
    @include font($fontSize: clamp(18px, 2vw, 22px), $lineHeight: clamp(22px, 4vw, 30px), $color: $textColor1);
  }

  img {
    width: 70px;
    @include margin($bottom: 30px);
  }
}

.tooltipBoxContainer {
  @include box($position: relative);

  &:hover {
    .tooltipBox {
      @include box($display: block, $opacity: 1);
    }
  }
}

.tooltipBox {
  @include box($background: $white, $radius: 5px, $display: none, $padding: 20px, $position: absolute, $minWidth: max-content, $top: 100%, $left: 50%, $opacity: 0);
  @include font($fontSize: clamp(14px, 1.1vw, 20px), $lineHeight: clamp(18px, 2vw, 24px), $fontWeight: normal, $color: $textColor1);
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.15);
  transform: translate(-50%, 18%);
  @include transition2;

  &::before {
    @include box($content: "", $background: $white, $width: 20px, $height: 20px, $position: absolute, $top: -20px, $left: 50%);
    @include margin($top: 10px, $left: -10px);
    transform: rotate(45deg);
  }

  a {
    color: $primary;
  }

  @include media-breakpoint-below(xs) {
    @include padding($all: 15px);
  }
}

.uptillText {
  @include font($fontFamily: $sedgwick, $fontSize: clamp(14px, 1.3vw, 30px), $lineHeight: clamp(34px, 1vw, 38px), $color: $textColor2, $textAlign: right);
}

.uptillTextMobile {
  @include font($fontFamily: $sedgwick, $fontSize: clamp(14px, 1.3vw, 30px), $color: $textColor2);
}

.ant-input-affix-wrapper,
.PhoneInput {
  @include box($border: 1px solid #ced4da, $radius: 4px);
  @include font($fontSize: 13px, $fontWeight: 400);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-textarea-with-clear-btn),
.PhoneInput {
  height: 36px;
}

.PhoneInput {
  @include box($padding: 4px 11px);

  .PhoneInputCountry {
    border-right: 1px solid #ced4da;
    min-width: 85px;
    @include margin($right: 14px);
    @include padding($right: 14px);
  }

  .PhoneInputInput {
    @include box($border: none, $outline: none);
  }

  .PhoneInputCountrySelectArrow {
    @include margin($left: 45px);
    display: none;
  }
}

.ant-form-item-control-input-content {
  position: relative;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.PhoneInputCountryIcon {
  width: 30px !important;
}

.PhoneInputCountryIconImg {
  // display: none !important;
}

.countryCode {
  @include box($position: absolute, $transform: translate(44px, -28px));
}

.countrySelectArrow {
  @include box($position: absolute, $transform: translate(80px, -25px));
  font-size: 9px;
}

::placeholder {
  color: #a7a7a7;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #a7a7a7;
}

::-ms-input-placeholder {
  color: #a7a7a7;
}

.required {
  color: $danger;
  @include margin($left: 2px);
}

.buttons {
  @include box($display: flex, $flexDirection: row, $alignItems: center, $justifyContent: space-between);
}

.prescription-audit-top-section {
  height: 100vh;
  background-position: left;

  @media screen and (max-width: 768px) {
    h1 {
      width: 100%;
    }
  }

  article {
    padding-top: 10%;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 15% 5%;
      text-align: center;
      p {
        margin: 24px auto;
        text-align: center;
      }
    }
  }

  p {
    margin-top: clamp(15px, 3%, 50px);
    font-size: clamp(14px, 1.2vw, 24px);
  }
}

.form-container {
  display: flex;
  margin-top: 7%;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.prescription-audit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-form-controls {
  .react-tel-input {
    width: fit-content !important;

    .form-control {
      background: transparent !important;
      border-radius: 30px;
      border: 2px solid #ffffff !important;
      height: 48px;
      color: #ffffff !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      font-size: 16px !important;
      letter-spacing: 1.1px;
      width: 250px;
    }

    .flag-dropdown,
    .selected-flag {
      background-color: transparent !important;
      border-radius: 30px 0 0 30px !important;
      display: none !important;
    }
  }
}

.green-button {
  background: #00b661;
  border: 2px solid #00d16f;
  border-radius: 30px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 20px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-left: 32px;
  height: 48px;
  width: fit-content;

  @media screen and (max-width: 768px) {
    margin-top: 24px;
    margin-left: 0;
  }

  img,
  .anticon {
    margin-left: 16px;
  }

  &:hover {
    background: transparent;
    border-color: #ffffff;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: no-drop;

    &:hover {
      background: #00b661 !important;
      border: 2px solid #00b661 !important;
    }
  }
}

.or-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;

  span {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #0085c0;
    font-size: 14px;
    border-radius: 50%;
  }

  &::before {
    background: #f1f1f1;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 1px;
  }
}

.link-button {
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  transition: all 0.3s linear;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
    cursor: no-drop;
  }
}

.form-controls {
  background: transparent !important;
  border-radius: 30px;
  border: 2px solid #ffffff !important;
  height: 48px;
  color: #ffffff !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-size: 16px !important;
  letter-spacing: 1.1px;
  width: 100%;

  &::placeholder {
    color: #bfbfbf;
  }

  &:focus-visible {
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-select-selector {
    background-color: transparent !important;
    border: 0px !important;
    height: 48px !important;
    font-weight: normal;
    box-shadow: none !important;
    .ant-select-selection-search-input {
      height: 48px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 46px !important;
    }
    .ant-select-selection-item {
      font-weight: 500;
      line-height: 46px !important;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
}

.place-holder-text {
  position: absolute;
  top: 50%;
  left: 58px;
  font-size: 16px;
  color: #dedede;
  transform: translate(0, -50%);
  pointer-events: none;
  letter-spacing: 1.1px;
}

.user-details-form-container {
  position: relative;
  background: #edf7ff;
  padding: 24px 5%;
  min-height: calc(100vh - 55px);

  @media screen and (max-width: 1024px) {
    padding: 32px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
    padding-bottom: 100px;
  }

  .user-details-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px;
    margin: 24px 0;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin: 20px 0;
    }

    .form-items {
      label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 8px;
      }
    }

    .form-controls {
      border-color: #575656 !important;
      color: #333333 !important;
      font-weight: 500;

      &::placeholder {
        color: #bbbbbb !important;
        opacity: 1;
        font-weight: normal;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .mobile-form-controls {
      .form-control {
        border-color: #575656 !important;
        color: #333333 !important;
        font-weight: 500;
      }
    }

    .place-holder-text {
      color: #bbbbbb !important;
      font-weight: normal;
    }
  }
}

.rx-logo {
  position: absolute;
  opacity: 0.2;
  width: 200px;
  bottom: 30px;
  right: 30px;

  @media screen and (max-width: 768px) {
    width: 64px;
    bottom: 16px;
    right: 16px;
  }
}

.rx-logo-top {
  position: absolute;
  opacity: 0.2;
  width: 100px;
  top: 15px;
  right: 15px;

  @media screen and (max-width: 768px) {
    width: 64px;
    top: 8px;
    right: 8px;
  }
}

.app-logo-top-left {
  position: absolute;
  width: 150px;
  top: 15px;
  left: 24px;

  @media screen and (max-width: 768px) {
    width: 64px;
    top: 8px;
    right: 8px;
  }
}

.otp-controls {
  display: flex;
  align-items: center;
  margin: 16px -8px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  input {
    width: 48px !important;
    height: 48px !important;
    border-radius: 8px !important;
    border: 1px solid #b5b5b5 !important;
    margin: 8px;
    font-size: 16px;
    color: #333333;
    font-weight: 500;

    @media screen and (max-width: 576px) {
      width: 100% !important;
    }
  }

  .otp-status {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 16px;
    margin-right: 16px;
  }
}

.reset-from {
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  bottom: 18%;
  right: 38%;
  cursor: pointer;
  display: none;
}

.submit-button {
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  margin-top: 0px;
  border: 2px solid #008df4;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  @media screen and (max-width: 576px) {
    margin-top: 8px;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: no-drop;
  }
}

.file-upload {
  label {
    display: inline-block;
    background-color: #7e7e7e;
    color: white;
    padding: 13px 15px 15px 13px;
    font-size: 16px;
    cursor: pointer;
    height: 48px;
    border-radius: 30px;
    min-width: 170px;
    text-align: center;
    justify-content: center;
  }
}

.file-upload-label-text {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 8px;
}

.upload-section {
  background: #defee9;
  padding: 32px;
  margin-left: -32px;
  margin-top: 16px;
  border-radius: 6px;
  width: fit-content;
  min-width: 60%;
  color: #333333;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

  h4 {
    color: #555555;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    padding: 16px;
  }
}

.section-heading {
  font-size: clamp(24px, 2vw, 62px);
  line-height: clamp(20px, 4vw, 81px);
  font-weight: bolder;
}

.bottom-bg {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      display: block;
      float: right;
      width: 100%;
      opacity: 0.7;
    }
  }
}

.uploaded-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #1f89c9;
  font-size: 16px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
}

.empty-label {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

.blue-text {
  color: #008df4;
  font-weight: 500;
}

.terms-of-use {
  padding: 24px;
  padding-top: 48px;
  background: #edf7ff;
}

.bank-label {
  display: inline-flex !important;
  align-items: center;
}

.sitemap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  & > * {
    font-size: clamp(14px, 1.1vw, 18px);
    line-height: clamp(16px, 2vw, 22px);
    color: #575656;
    margin: 16px;
    @include media-breakpoint-below(xs) {
      margin: 16px 8px;
    }

    &:hover {
      color: #1e88c9;
    }
  }
}

.section-heading {
  color: #626060;
  font-weight: 600;
  font-style: normal;
  font-size: clamp(20px, 2vw, 32px);
  line-height: clamp(24px, 5vw, 36px);
  margin-bottom: 16px;
}

.contents-wrapper {
  p {
    margin-bottom: 16px;
    font-size: clamp(14px, 1vw, 16px);
    line-height: clamp(16px, 2vw, 24px);
  }
  h3 {
    margin-bottom: 16px;
    font-size: clamp(16px, 1.3vw, 24px);
    line-height: clamp(20px, 4vw, 32px);
    font-weight: 600;
  }
}

.contents-collapse {
  margin-top: 24px;
  .ant-collapse-item {
    border: 1px solid #ddd;
    margin-top: -1px;

    .ant-collapse-content-box {
      font-size: clamp(12px, 1vw, 16px);
      line-height: clamp(16px, 2vw, 22px);
      color: #555555;

      & > *:first-child {
        margin-top: 0px;
      }

      p,
      ul {
        margin-top: 16px;
      }

      ul {
        list-style: none;
        list-style-position: inside;
      }
    }
  }
}

.contact-section {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
  @include media-breakpoint-below(sm) {
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
  }
}

.contact-banner {
  max-width: 250px;
  @include media-breakpoint-below(xs) {
    max-width: 200px;
  }
}

.contact-info {
  margin-left: 64px;
  @include media-breakpoint-below(sm) {
    margin-left: 0px;
    margin-top: 32px;
  }
  h4 {
    color: #626060;
    font-weight: 500;
    font-style: normal;
    font-size: clamp(18px, 1vw, 24px);
    line-height: clamp(22px, 2vw, 28px);
    margin-bottom: 4px;
  }
  a {
    color: #1e88c9;
    font-weight: 500;
    font-style: normal;
    font-size: clamp(20px, 1.5vw, 32px);
    line-height: clamp(24px, 4vw, 36px);
    margin-bottom: 16px;
    display: inline-block;
  }
}
